@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i');

$cor-1:#171717;
$cor-2: #333333;
$cor-3: #555555;
$cor-4:#999999;
$cor-destaque: #AABD72;
$cor-clara-1: #fefefe;
$cor-clara-2: #dddddd;

body{
	background-color:#e2e2e2;
		&.smart-style-4{
		font-family: 'Open Sans', sans-serif !important;
		}
}

input:focus, input:hover { 
    outline: none !important;
    border: 2px solid $cor-destaque !important;
    box-shadow: 0 0 5px $cor-destaque !important;
}

/* Login */

.label-error{
	margin: 0 -14px 15px;
	padding: 5px 14px;
	width: 100%;
	background: #ff8f8f;
}

/* Menu Topo */

.menu-on-top{
	aside{
		&#left-panel{
			border-bottom: none;
			border-top: none;
			background-color:$cor-1;
			-webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			nav > ul > li{
				border-right: 1px solid $cor-3;
				border-left: 1px solid $cor-3;
				background-color: $cor-2;
				margin-left: -1px;
			    height: 70px !important;
			    width: 80px;
			    border-bottom: 5px solid $cor-2;
		    	&.active{
		    		border-bottom: 5px solid $cor-destaque;
		    		a{
		    			color: $cor-clara-1 !important;
		    			font-weight: normal !important;
		    		}
		    	}
			    &:last-child{
			    	width:initial;
			    }
				&.menu-telas,
				&.menu-estrutua,
				&.menu-anexos {
					border-top: 5px solid #555555;
				}
				&.menu-telas{ margin-left: 10px; }
				&.menu-anexos{ margin-right: 10px; }



			    > a{
					padding: 12px 0 !important;
					color: $cor-4 !important;
						i{
							display: inline-block;
							margin: 0 auto;
						}
			    }
			    &:hover, &:focus{
					background: $cor-3;
					border-bottom: 5px solid $cor-destaque;
						> a{
							color:$cor-clara-2!important;
						  border-left: none;
							border-right: none;
							padding-left: 0px !important;
							padding-right: 0px !important;
						}
		    	}
			    > ul{
					padding: 0px;
					border: 0px;
    				margin-top: 2px;

					> li{
						background-color: $cor-2;
						border-bottom: 0px;
							> a{
								color: $cor-4 !important;
								padding-left: 35px !important;
							}
							&.active{
			    			border-right: 5px solid $cor-destaque;
				    			> a{
					    			color: $cor-clara-1 !important;
    								font-weight: normal !important;
					    		}
					    		&:after{
					    			color: $cor-clara-1; 
					    		}
			    		}
			    		&:hover, &focus{
								border-right:5px solid $cor-destaque;
			    		}
			    		&:after{
							content: "\f007";
						    display: block;
						    height: 20px;
						    width: 20px;
						    font-family: FontAwesome;
						    position: absolute;
						    left: 0;
						    top: 12px;
						    color: $cor-4;
						    font-size: 18px;
						    padding: 0px 10px;
						    line-height: 18px;
			    		}
			    		&.menu-logs{
			    			&:after{
			    				content: "\f022";
			    			}
			    		}
					}
		    	}
			}
			.logout{
				.menu-item-parent{
					padding-top: 25px;
					margin-right: 5px;
					color: $cor-4;
						a{
							color: $cor-4;
								&:first-child{
									color: $cor-clara-1;
								}
								&:hover, &:focus{
									color: $cor-destaque;
								}
								&[routerlink="/ajuda"]{
									display: inline-block;
									height: 24px;
									width: 20px;
									overflow: hidden;
									text-indent: 100px;
									margin-bottom: -10px;
									position: relative;
									padding: 0px;
										&:after{
											content: "\f059";
											font-family: FontAwesome;
											left: 0;
											top: 0;
											text-indent: 0;
											position: absolute;
											font-size: 18px;
											padding: 1px;
										}
								}
								&:last-child{
									display: inline-block;
									height: 24px;
									width: 20px;
									overflow: hidden;
									text-indent: 100px;
									margin-bottom: -10px;
									position: relative;
									padding: 0px;
										&:after{
											content: "\f08b";
											font-family: FontAwesome;
											left: 0;
											top: 0;
											text-indent: 0;
											position: absolute;
											font-size: 18px;
											padding: 1px;
										}
								}
						}
						i{
							display: none;
						}
				}
			}
		}
	}
}

.smart-style-4{
	nav{
		ul{
			li{
				&.open{
					> a{
						background: $cor-4;
					}
				}
			}
		}
	}
}

.menu-on-top .menu-item-parent{
	max-width: initial;
	min-width: 80px;
}

/* Breadcrumb */

.smart-style-4{
	#content{
		> .row{
			&:first-child{
				background-color:#ffffff;
				margin-top:-10px !important;
				padding:0px !important;
			}
		}
	}
	.page-title{
		margin: 0px;
		font-size: 22px;
		padding: 13px 0px;
		font-weight: normal;
		color: $cor-2;
		
		> span{
			padding-left: 12px;
			margin-left: 5px;
			position: relative;
				&:last-child{
					font-size: 16px;
					color: $cor-destaque;
				}
				&::before{
					content: "\f0da";
					font-family: FontAwesome;
					left: 0;
					top: -1px;
					position: absolute;
					font-size: 18px;
					padding: 1px;
					color: $cor-4;
				}
		}
	}
}

/* Conteudo */

/* Botao Adicao */

.header-btn{
	margin-top: 5px;
	text-align: center;
	display: inline-block;
	margin: 0 15px 15px 0;
	min-width: 150px;
	position: relative;
	background-color: #aabd72;
	border: none;
	height: 30px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
		&:hover, &:focus{
    		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    		background-color: $cor-destaque;
		}
		i {
			margin-right: 10px;
			padding-right: 10px;
			border-right: 1px solid white;
		}
}

/* Box tabela */

.jarviswidget, .jarviswidget.well{
	> header{
		display:none;
	}
	.dt-toolbar{
		padding: 10px 10px 5px;
		background: $cor-2;
		border-radius: 2px 2px 0px 0px;
			.dataTables_filter{
				label{
					&:hover, &:focus{
						.input-group-addon{
							background-color: $cor-destaque;
							-webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
						}
					}
					.input-group-addon{
						background-color: $cor-4;
						color: #fefefe;
						border: none;
						-webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
						transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
					}
				}
			}
			.dt-buttons{
				.btn-default{
					color: #fff;
					background-color: #838383;
					border-color: #333;
					width: 32px;
					padding: 0 10px 0 20px;
					line-height: 30px;
					text-indent: 15px;
					overflow: hidden;
					position: relative;
					-webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
					transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
						&:before{
							content: "\f067";
							font-family: FontAwesome;
							left: -15px;
							top: 7px;
							position: absolute;
							font-size: 18px;
							padding: 0 8px;
							border-right: 1px solid #fff;
							line-height: 18px;
						}
						&:hover, &:focus{
							color: #fefefe;
							width: 90px;
							background-color: #aabd72;
							border-color: #333;
							-webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
							transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
						}
						&.buttons-copy{
							&:before{
								content:"\f0ea";
							}
						}
						&.buttons-excel{
							&:before{
								content:"\f1c3";
							}
						}
						&.buttons-pdf{
							&:before{
								content:"\f1c1";
							}
						}
						&.buttons-print{
							&:before{
								content:"\f02f";
							}
						}
						span{
							padding: 0 3px;
						}
				}
			}
	}
	table{
		padding: 10px;
	}
	.dt-toolbar-footer{
		background-color: transparent;
		border-top:0px;
			div{
				.dataTables_info{
					color: $cor-4;
					font-style: normal;
					font-weight: 100;
				}
			}
	}
	> div{
		border-width: 0;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
	}
	&.well{
		h6{
			display: none;
		}
		button{
			width: 150px;
			padding: 5px;
			background-color: $cor-destaque;
			border: none;
			box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
			transition: all 0.3s cubic-bezier(.25,.8,.25,1);
				&:hover, &:focus{
					box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
					transition: all 0.3s cubic-bezier(.25,.8,.25,1);
					background-color: $cor-destaque;
				}
		}
		.node-content-wrapper{
			&:hover, &:focus{
				background: $cor-clara-2;
			}
			button{
			    width: initial;
				padding: 0;
				background-color: transparent;
				border: none;
				box-shadow: none;
				color: $cor-2;
				font-size: 18px;
				margin: 0 5px;
				transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
					&:hover,&:focus{
						color: $cor-destaque;
					}
			}
		}
	}

	.form{
		.form-actions{
		display: block;
		padding: 0;
		border-top: 0;
		height:50px;
		background: transparent;
		position: relative;
			.btn{
				float: left;
				height: 50px;
				margin: 0;
				padding: 0;
				cursor: pointer;
				width: 50%;
				border-radius: 0;
				border: none;
				background-color: $cor-2;
				-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				color: $cor-clara-1;
					&:hover, &:focus, &:last-child{
						background-color: $cor-destaque;
						-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
						transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
					}
			}
			&:after{
				content: "";
				height: 30px;
				width: 1px;
				position: absolute;
				left: calc(50% - 1px);
				top: 10px;
				border-left: 1px solid $cor-4;
			}
		}

	}
}

.form-control:focus {
    border-color: #aabd72;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(170, 189, 114);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(170, 189, 114);
}

/* Tabela */

.dataTable td, .dataTable th, .dataTable thead, .dataTable thead td, .dataTable tr, .table thead tr {
    background-color: transparent !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid $cor-clara-1!important;
    background-image: none;
		-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
		transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dataTable tr{
	&:hover, &:focus{
		td{
    	border-bottom: 1px solid $cor-clara-2!important;
		-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
		transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}
	td{
		i.fa{
			font-size:18px;
			margin-right: 3px;
		}
		a{
			&.link{
				text-indent: 1px;
				width: 20px;
				height: 20px;
				display: inline-block;
				overflow: hidden;
				margin: 0 10px 0 0;
				color: $cor-2;					
					&:hover, &:focus{
						color: $cor-destaque;
					}
			}
		}
	}
}

/* Paginação */
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: $cor-clara-1;
    background-color: $cor-3;
    border-color: $cor-2;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.pagination>li>a, .pagination>li>span{
    color: $cor-2;
	box-shadow: none;
	border: 0px;
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		&:hover, &:focus{
			box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  			transition: all 0.3s cubic-bezier(.25,.8,.25,1);
			background-color: $cor-clara-1;
    		color:$cor-destaque;
		}
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover{
	color:$cor-clara-2;
	box-shadow: none;
}

.pagination{
	li{
		&.previous, &.next{
			> a{
				width: 25px;
				height: 25px;
				padding: 0;
				text-indent: 100px;
				overflow: hidden;
				position: relative;
					&:before{
						content: "\f0d9";
						font-family: FontAwesome;
						left: 3px;
						top: 5px;
						position: absolute;
						font-size: 18px;
						padding: 0 5px;
						line-height: 18px;
						text-indent:0px;
					}
			}
		}
		&.next{
			> a{
				&:before{
					content: "\f0da";
					left: 5px;
				}

			}
		}
	}
}

/* Modal */

.modal-content{
	.smart-form{
		footer{
			display: block;
			padding: 0;
			border-top: 0;
			height:50px;
			background: transparent;
			position: relative;
				.btn{
					float: right;
					height: 50px;
					margin: 0;
					padding: 0;
					cursor: pointer;
					width: 50%;
					border-radius: 0;
					border: none;
					background-color: $cor-2;
					-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
					transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
					color: $cor-clara-1;
						&:hover, &:focus, &:first-child{
							background-color: $cor-destaque;
							-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
							transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
						}
				}
				&:after{
					content: "";
					height: 30px;
					width: 1px;
					position: absolute;
					left: calc(50% - 1px);
					top: 10px;
					border-left: 1px solid $cor-4;
				}
		}
	}
}

/* Estrutura */

treenode:last-child > .regular-node.tree-node-expanded:after {
	opacity: 0;
}

treenodeexpander {
	position: absolute;
	z-index: 100;
	left: 0;
}

span.toggle-children-wrapper.toggle-children-wrapper-expanded {
	padding: 3px 1px 0;
}

.node-wrapper {
	.content {
		.tree-view-item {
			position: relative;
			display: block;
			margin-right: 88px;
			input[type=text] {
				width: calc(100% - 40px);
				padding: 2px 5px 2px;
			}
			input[type=checkbox] {
				margin-left: -8px;
				height: 12px;
				margin-right: 1px;
			}
		}
		.pull-right {
			display: block;
			position: absolute;
			right: 15px;
			top: 15px;
		}
	}
}

.regular-node{
	position:relative;

	.node-wrapper {
		display: flex;
		align-items: flex-start;
		position: relative;
		padding-left: 13px !important;
	}

	&.tree-node-collapsed{
		&:before{
			border-left: 1px dashed $cor-3;
			/*content: "";*/
			height: 0px;
			position: absolute;
			left: 5px;
			top: 25px;
			-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
			transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
		}
		.regular-node{
			.node-wrapper{
				&:before{
					content: "";
					border-top: 1px dashed #555;
					width: 0px;
					position: absolute;
					top: 25px;
					left: -10px;
					-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
					transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			}
		}
	}

	// pular o primeiro nível
	.regular-node{
		&.tree-node-expanded{
			&:after{
				border-left: 1px dashed #555;
				content: "";
				height: calc( 100% - 55px );	
				width: 23px;
				position: absolute;
				left: -15px;
				top: 26px;
				-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}
	}

	&.tree-node-expanded{
		&:before{
			border-left: 1px dashed #555;
			border-bottom: 1px dashed #555;
			/*content: "";*/
			height: 56px;
			position: absolute;
			left: 5px;
			bottom: 33px;
			-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
			transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
		}

		.regular-node{
			.node-wrapper{
				&:before{
					border-left: 1px dashed #555;
					border-bottom: 1px dashed #555;
					content: "";
					height: 56px;	
					width: 23px;
					position: absolute;
					left: -15px;
					bottom: 28px;
					-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
					transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			}
		}
	}
	&.tree-node-active{
		> .node-wrapper{
			> .node-content-wrapper{
				    background-color: $cor-clara-2;
			}
		}
	}
}

.tree-node-active{
	 > .node-wrapper{
	 	> .node-content-wrapper{
	 		background-color: $cor-clara-2 !important;
	 	}
	 }
} 

.special-plus{
	width: 100%;
	margin-left: 0px;
		.node-content-wrapper{
			padding: 9px 2px;
			background-color: $cor-destaque;
			-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
			transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				&:hover{
					box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
					background-color: $cor-destaque !important;
					-webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
					transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
				}
		}
		button{
			margin-left:-37px !important;
			position: relative;
				&:after{
					content: "Clique aqui para adicionar uma nova tela";
					font-size: 13px;
					font-family: "Open Sans";
					position: absolute;
					left: 55px;
					top: 2px;
					color: $cor-clara-1;
				}
		}
}